import { Highlightable, ListKeyManagerOption } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, HostListener, Input, ViewChild } from '@angular/core';
import { UIKitSelect } from './select';

@Component({
  selector: 'uikit-select-option',
  template: `
    <ui-checkbox *ngIf="uikitSelect.multiple" class="checkbox" [checked]="selected" [disabled]="disabled"></ui-checkbox>
    <div class="content">
      <div #contentRef class="injected-content"><ng-content></ng-content></div>
      <ng-container  *ngIf="!contentRef.children.length" ><span [title]="text">{{ text }}</span></ng-container>
    </div>
  `,
  styleUrls: ['./select-option.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UIKitSelectOption<T> implements ListKeyManagerOption, Highlightable {
  @Input() selector: UIKitSelect<T>;
  @Input() readonly value: T;
  @Input() readonly text: string = '';

  _disabled: boolean;
  @Input() @HostBinding('class.disabled')
  get disabled(): boolean {
    return this._disabled === undefined ? false : this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
  }

  _compact: boolean;
  @Input('compact')
  @HostBinding('class.compact')
  get compact(): boolean {
    return this._compact;
  }
  set compact(value: BooleanInput) {
    this._compact = coerceBooleanProperty(value);
  }
  _filter: boolean;
  @Input('filter')
  @HostBinding('class.filter')
  get filter(): boolean {
    return this._filter;
  }
  set filter(value: BooleanInput) {
    this._filter = coerceBooleanProperty(value);
  }

  _selected = false;
  @HostBinding('class.selected')
  @Input()
  public set selected(v: boolean) {
    this._selected = v;
    this.selector?.cdr.markForCheck();
  }
  public get selected() : boolean {
    return this._selected;
  }


  @HostBinding('class.active') active = false;

  @HostBinding('class.hidden') hidden = false;

  @ViewChild('contentRef') contentRef: ElementRef<HTMLDivElement>;

  constructor(public uikitSelect: UIKitSelect<T>, public el: ElementRef<HTMLElement>, private cdr: ChangeDetectorRef) { }

  setSelected(selected: boolean) {
    this.selected = selected;
    this.cdr.markForCheck();
  }

  getLabel(): string {
    return (this.text || this.contentRef?.nativeElement.innerText || this.value.toString()).trim();
  }

  setActiveStyles(): void {
    this.active = true;
  }

  setInactiveStyles(): void {
    this.active = false;
  }

  @HostListener('click', ['$event'])
  onClick(event: UIEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (!this.disabled) {
      this.uikitSelect.selectOption(this);
    }
  }
}
