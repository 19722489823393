import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIKitFormField } from './form-field';
import { UIKitLabel } from '../label/label';
import { UIKitError } from './error';
import { UIKitMessage } from './message';
import { UIKitPrefix, UIKitSuffix } from '.';
import { UIKitIconModule } from '../icon';
import { FancyFormFieldComponent } from './fancy-form-field/fancy-form-field.component';

@NgModule({
  declarations: [
    UIKitFormField,
    UIKitLabel,
    UIKitError,
    UIKitMessage,
    UIKitPrefix,
    UIKitSuffix,
    FancyFormFieldComponent
  ],
  imports: [CommonModule, UIKitIconModule],
  exports: [
    UIKitFormField,
    UIKitLabel,
    UIKitError,
    UIKitMessage,
    UIKitPrefix,
    UIKitSuffix,
    FancyFormFieldComponent
  ],
})
export class UIKitFormFieldModule { }
