import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { UIKitIconModule } from 'app/shared/ui-kit/icon';
import { InsightCheckboxModule } from 'app/shared/ui/checkbox';
import { UIKitFormFieldModule } from '../form-field/form-field.module';
import { UIKitDropdown } from './dropdown';
import { UIKitSelect } from './select';
import { UIKitSelectOption } from './select-option';
import { UIKitSelectSearch } from './select-search/select-search';
import { UIKitSelectOptionGroupLabel } from './select-option-group-label';


@NgModule({
  imports: [PortalModule, OverlayModule,
    FormsModule, UIKitIconModule, UIKitFormFieldModule,
    InsightCheckboxModule, CommonModule, TranslocoModule],
  exports: [UIKitSelect, UIKitSelectOption, UIKitSelectOptionGroupLabel, UIKitDropdown, UIKitSelectSearch],
  declarations: [UIKitSelect, UIKitSelectOption, UIKitSelectOptionGroupLabel, UIKitDropdown, UIKitSelectSearch]
})
export class UIKitSelectModule { }
