import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  QueryList,
  ViewChild,
  forwardRef,
  Renderer2
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NeedsToUnsubscribe } from 'app/shared';
import { Subject, combineLatest } from 'rxjs';
import { delay, startWith, takeUntil } from 'rxjs/operators';
import { UIKitDropdown } from './dropdown';
import { UIKitSelectOption } from './select-option';
import { UIKitSelectSearch } from './select-search/select-search';

/**
 * Parent component that can contain multiple UIKitSelectionOptions.
 * Selection state is saved in the option components.
 */
@Component({
  selector: 'uikit-select',
  templateUrl: './select.html',
  styleUrls: ['./select.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UIKitSelect),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UIKitSelect<T>
  extends NeedsToUnsubscribe
  implements AfterContentInit, ControlValueAccessor {
  @Input() placeholder: string;
  @Input() icon: string;

  _required = false;
  private keyDownListener: () => void;
  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
  }

  _disabled = false;
  @Input()
  @HostBinding('class.disabled')
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
  }

  _multiple = false;
  @Input()
  get multiple(): boolean {
    return this._multiple;
  }
  set multiple(value: BooleanInput) {
    this._multiple = coerceBooleanProperty(value);
  }

  _survey: boolean;
  @Input('survey')
  @HostBinding('class.survey')
  get survey(): boolean {
    return this._survey;
  }
  set survey(value: BooleanInput) {
    this._survey = coerceBooleanProperty(value);
  }

  _minimal: boolean;
  @Input('minimal')
  @HostBinding('class.minimal')
  get minimal(): boolean {
    return this._minimal;
  }
  set minimal(value: BooleanInput) {
    this._minimal = coerceBooleanProperty(value);
  }

  _compact: boolean;
  @Input('compact')
  @HostBinding('class.compact')
  get compact(): boolean {
    return this._compact;
  }
  set compact(value: BooleanInput) {
    this._compact = coerceBooleanProperty(value);
  }

  _filter: boolean;
  @Input('filter')
  @HostBinding('class.filter')
  get filter(): boolean {
    return this._filter;
  }
  set filter(value: BooleanInput) {
    this._filter = coerceBooleanProperty(value);
  }

  _theme: boolean;
  @Input('theme')
  @HostBinding('class.theme')
  get theme(): boolean {
    return this._theme;
  }
  set theme(value: BooleanInput) {
    this._theme = coerceBooleanProperty(value);
  }

  @Input() dropdownMaxHeight: string;

  /** Emits when an option is clicked on or selected with keyboard */
  @Output() selectionEvent = new EventEmitter<T>();

  @HostBinding('class.showing') showing = false;
  @HostBinding('class.selected') get hasSelected() {
    return this.selectedOptions?.length > 0
  }

  get selectedOptions(): UIKitSelectOption<T>[] {
    return this.options.filter((opt) => opt.selected);
  }

  @ViewChild('input') input: ElementRef<HTMLInputElement>;
  @ViewChild(UIKitDropdown) dropdown: UIKitDropdown;
  @ViewChild('dropdownOptionContainer') dropdownOptionContainer: ElementRef<HTMLElement>;
  @ContentChild(UIKitSelectSearch) search: UIKitSelectSearch;
  @ContentChildren(UIKitSelectOption) options: QueryList<UIKitSelectOption<T>>;

  get displayText(): string {
    return (
      this.hasSelected
        ? this.selectedOptions?.map(x => x.getLabel()).join(', ')
        : this.placeholder
    );
  }

  get dropdownPanelClass() {
    return [
      ...(this.survey ? ['survey'] : []),
      ...(this.multiple ? ['multiple'] : []),
      ...(this.compact ? ['compact'] : []),
      ...(this.filter ? ['filter'] : [])
    ];
  }

  onTouchedFn = () => { };
  onChangeFn = (_: T | T[]) => { };

  private readonly _selectedValues$ = new Subject<T | T[]>();
  private readonly _optionsChanged$ = new Subject();
  keyManager: ActiveDescendantKeyManager<UIKitSelectOption<T>>;

  constructor(public cdr: ChangeDetectorRef, private renderer: Renderer2) {
    super();

    combineLatest([this._selectedValues$.asObservable(), this._optionsChanged$.asObservable()])
      .pipe(
        takeUntil(this.unsubscribe$))
      .subscribe(([values, _]) => {

        if (values == null || !this.options?.length) {
          return;
        }

        for (const opt of this.options) {
          if (this.multiple && Array.isArray(values)) {
            opt.setSelected(values.includes(opt.value));
          } else {
            opt.setSelected(values === opt.value);
          }
        }
        this.onChange();
        this.cdr.markForCheck();
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.removeKeyboardListener();
  }

  ngAfterContentInit() {

    this.keyManager = new ActiveDescendantKeyManager<UIKitSelectOption<T>>(this.options)
      .withVerticalOrientation()
      .withWrap()
      .withHomeAndEnd()
      .withPageUpDown()
      .withAllowedModifierKeys(['shiftKey'])
      .skipPredicate((item) => item.disabled || item.hidden);

    this.keyManager.change.subscribe(() => {
      if (this.showing && this.dropdown) {
        this.smartScroll();
      } else if (!this.showing && !this.multiple && this.keyManager.activeItem) {
        this.keyManager.activeItem.selected = true;
      }
    });

    if (this.search) {
      this.search.searchChange.asObservable().subscribe(search => {
        const visibleOptions = this.options.filter(o => !o.hidden);
        if (visibleOptions.length === 1
          || this.keyManager.activeItem.disabled
          || ((visibleOptions.findIndex(o => o === this.keyManager.activeItem) !== 0) && !!search)) {
          this.keyManager.setFirstItemActive();
          this.smartScroll();
        }
      });
    }

    this.options.changes.pipe(
      takeUntil(this.unsubscribe$),
      delay(0),
      startWith(null))
      .subscribe(() => {
        this._optionsChanged$.next(true);
        this.cdr.markForCheck();
      })
  }

  onChange() {
    this.onChangeFn(
      this.multiple
        ? this.selectedOptions?.map(x => x.value) ?? []
        : this.selectedOptions?.[0]?.value
    );
    this.cdr.markForCheck();
  }

  showDropdown() {
    if (this.disabled || this.showing) {
      return;
    }

    this.keyDownListener = this.renderer.listen(window, 'keydown', (event: KeyboardEvent) => {
      this.onKeyDown(event);
     });

    this.dropdown.show();
    if (!this.options?.length) {
      return;
    }

    const activeItem = this.selectedOptions?.[0] ?? this.options?.find(o => (!o.hidden && !o.disabled));
    if (activeItem) {
      this.keyManager.setActiveItem(activeItem);
    }

    if (this.search) {
      this.search?.focus();
    }

    setTimeout(() => {
      this.smartScroll();
    }, 0);

  }

  hideDropdown() {
    this.dropdown.hide();
    this.removeKeyboardListener();
  }

  removeKeyboardListener() {
    if (this.keyDownListener) {
      this.keyDownListener();
      this.keyDownListener = null;
    }
  }

  onKeyDown(event: KeyboardEvent) {
    if (this.dropdown.showing && !(event.ctrlKey || event.metaKey)) {

      switch (event.key) {
        case 'Enter': {
          if (this.keyManager.activeItem.value !== this.selectedOptions[0]?.value) {
            this.selectOption(this.keyManager.activeItem);
            this.keyManager.cancelTypeahead();
          } else {
            this.hideDropdown();
          }
          break;
        }
        case 'ArrowUp':
        case 'ArrowDown': {
          this.keyManager.onKeydown(event);
          event.preventDefault();
          this.smartScroll();
          break;
        }
        case 'Tab': {
          event.preventDefault();
          break;

        }
        case 'Escape': {
          this.hideDropdown();
          return
        }
        default: {
          this.smartScroll();
          break;
        }
      }
    }
  }

  /**
   * Sets the scroll so that the activeItem is shown when it comes out of view,
   * either at the bottom or top depending on where it is
   */
  smartScroll() {
    const container = this.dropdownOptionContainer.nativeElement;
    const item = this.keyManager.activeItem.el.nativeElement;

    const containerTop = container.scrollTop;
    const containerBottom = container.scrollTop + container.clientHeight;
    const itemTop = item.offsetTop - container.offsetTop;
    const itemBottom = itemTop + item.clientHeight;

    if (itemBottom > containerBottom) {
      container.scrollTo({ top: itemBottom - container.clientHeight });
    } else if (itemTop < containerTop) {
      container.scrollTo({ top: itemTop });
    }
  }

  selectOption(clickedOption: UIKitSelectOption<T>): void {
    if (this.multiple) {
      const selectedOptionsSet = new Set(this.selectedOptions);
      if (selectedOptionsSet.has(clickedOption)) {
        selectedOptionsSet.delete(clickedOption);
      } else {
        selectedOptionsSet.add(clickedOption);
      }
      // Filter by all options to get the selected options in the correct order
      const selectedOptions = this.options.filter(option =>
        selectedOptionsSet?.has(option)
      );
      this.writeValue(selectedOptions.map(x => x.value));
    } else {
      this.writeValue(clickedOption.value);
      this.hideDropdown();
    }

    this.input.nativeElement.focus();
    this.onChange();
    this.selectionEvent.emit(clickedOption.value);
    this.keyManager?.setActiveItem(clickedOption);
  }

  registerOnChange(fn: (_: T | T[]) => unknown): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: () => unknown): void {
    this.onTouchedFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: T | T[]): void {
    this._selectedValues$.next(obj);
  }

  onTouched() {
    this.onTouchedFn();
  }

  isShowing(event: boolean) {
    this.showing = event;
  }
}
