import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ViewChild,
  forwardRef
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { DateRangeDescriptor, IgxDatePickerComponent, WEEKDAYS } from '@infragistics/igniteui-angular';
import { IBaseCancelableBrowserEventArgs, IBaseEventArgs } from '@infragistics/igniteui-angular/lib/core/utils';
import { IDatePickerValidationFailedEventArgs } from '@infragistics/igniteui-angular/lib/date-picker/date-picker.common';
import { Store } from '@ngrx/store';
import { AppState } from 'app/state/app.state';
import { take } from 'rxjs/operators';


@Component({
  selector: 'uikit-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => UIKitDatePicker)
  }]
})
export class UIKitDatePicker implements Partial<IgxDatePickerComponent>, ControlValueAccessor, Validator {

  // This datepicker component is designed to provide a similar API to that of IgxDatePickerComponent.
  // In order to keep the code clean, only the parts of the API that we use are implemented.
  // Feel free to add more if needed in the future.

  @ViewChild(IgxDatePickerComponent, { static: true }) private igxDatePicker: IgxDatePickerComponent;
  @Input() set value(value: string | Date) {
    this._value = value;
    this.writeValue(value);
  }
  private _value: string | Date;
  @Input() public disabledDates: DateRangeDescriptor[] = [];
  @Input() public disabled = false;
  @Input() public weekStart: WEEKDAYS | number;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('label') public uikitLabel = '';

  @Input() public useFormControl = false;
  @Output() public valueChange = new EventEmitter<Date>();
  @Output() public closed = new EventEmitter<IBaseEventArgs>();
  @Output() public opening = new EventEmitter<IBaseCancelableBrowserEventArgs>();
  @Output() public validationFailed = new EventEmitter<IDatePickerValidationFailedEventArgs>();
  @Input() formControl: AbstractControl;

  constructor(private store: Store<AppState>) { }

  ngOnInit() {
    if (this.weekStart == null) {
      this.store.select(state => state.userInfo)
        .pipe(take(1))
        .subscribe(userInfo => this.weekStart = userInfo.languageCode === 'sv' ? WEEKDAYS.MONDAY : WEEKDAYS.SUNDAY);
    }
  }

  ngAfterViewInit() {
    if (this.useFormControl && this.formControl) {
      this.formControl.markAsPristine();
      this.formControl.markAsUntouched();
    }
  }

  writeValue(obj: any): void {
    this.igxDatePicker.writeValue(obj);
  }
  registerOnChange(fn: any): void {
    this.igxDatePicker.registerOnChange(fn);
  }
  registerOnTouched(fn: any): void {
    this.igxDatePicker.registerOnTouched(fn);
  }
  setDisabledState(isDisabled: boolean): void {
    this.igxDatePicker.setDisabledState(isDisabled);
  }

  validate(control: AbstractControl): ValidationErrors {
    return this.igxDatePicker.validate(control);
  }
  registerOnValidatorChange?(fn: () => void): void {
    this.igxDatePicker.registerOnValidatorChange(fn);
  }
}
