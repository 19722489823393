import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-fancy-form-field',
  templateUrl: './fancy-form-field.component.html',
  styleUrls: ['./fancy-form-field.component.scss']
})
export class FancyFormFieldComponent {
  @Input() icon: string;

}
