import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { UIKitSelect } from '../select';

@Component({
  selector: 'uikit-select-search',
  styleUrls: ['./select-search.scss'],
  template: `
    <uikit-form-field #formField [style.width]="'100%'" [style.margin]="'0'" [compact]="true">
      <input #searchInput uikit-input [placeholder]="placeholder" (keyup)="keyup(searchInput.value, $event)" (blur)="blur()" />
      <uikit-icon uikit-suffix icon="search"></uikit-icon>
    </uikit-form-field>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UIKitSelectSearch {

  @Input() placeholder = '';
  @Output() searchChange = new EventEmitter<string>();

  @ViewChild('searchInput', { read: ElementRef }) searchInput: ElementRef<HTMLInputElement>;
  @HostBinding('tabindex') tabindex = 0;

  _filter: boolean;
  @Input('filter')
  @HostBinding('class.filter')
  get filter(): boolean {
    return this._filter;
  }
  set filter(value: BooleanInput) {
    this._filter = coerceBooleanProperty(value);
  }

  public focus() {
    this.searchInput.nativeElement.value = '';
    this.searchInput.nativeElement.focus();
  }

  keyup(text: string, event: KeyboardEvent) {
    if (
      'Escape' === event.key ||
      (['ArrowUp', 'ArrowDown'].includes(event.key))
    ) {
      this.blur();
      return;
    }

    for (const opt of this.select.options) {
      const elementText = opt.getLabel().toLowerCase();
      const searchText = text?.toLowerCase() ?? '';
      opt.hidden = !elementText.includes(searchText);
    }

    this.searchChange?.emit?.(text)
  }

  blur() {
    this.select.input.nativeElement.focus();
  }

  constructor(private select: UIKitSelect<unknown>) { }
}
