<label *ngIf="uikitLabel" class="label">{{uikitLabel}}</label>
<igx-date-picker (valueChange)="valueChange.emit($event)" [disabledDates]="disabledDates" [displayDensity]="'compact'"
  [mode]="'dialog'" [placeholder]="' '" [displayFormat]="'yyyy-MM-dd'" [ngClass]="{'disabled': disabled}"
  [disabled]="disabled" (validationFailed)="validationFailed.emit($event)" [weekStart]="weekStart"
  (opening)="opening.emit($event)" (closed)="closed.emit($event)">
  <igx-picker-toggle igxSuffix>
    <uikit-icon icon="calendar" [block]="true"></uikit-icon>
  </igx-picker-toggle>
  <igx-picker-clear igxSuffix> </igx-picker-clear>
</igx-date-picker>
