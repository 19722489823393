import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIKitDatePicker } from './date-picker.component';
import { IgxDatePickerModule, IgxInputGroupModule } from '@infragistics/igniteui-angular';
import { UIKitIconModule } from '../icon';


@NgModule({
    declarations: [UIKitDatePicker],
    imports: [
        CommonModule,
        IgxDatePickerModule,
        IgxInputGroupModule,
        UIKitIconModule
    ],
    exports: [UIKitDatePicker]
})
export class UIKitDatePickerModule { }
