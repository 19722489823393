<div class="input-wrapper">
  <div class="input" [class.required]="required" #dropreference (click)="showDropdown()">
    <div class="label" *ngIf="minimal">{{ displayText ?? (placeholder || 'INSIGHT.GLOBAL.SELECT_CHOOSE' | transloco) }}
    </div>
    <ng-container *ngIf="icon">
      <div class="icon-wrapper">
        <uikit-icon [icon]="icon"></uikit-icon>
      </div>
      <input [hidden]="minimal" #input type="text" [disabled]="disabled" readonly
        [value]="displayText && hasSelected ? displayText : ''" (keydown)="onKeyDown($event)" (blur)="onTouched()"
        autocomplete="off" />
    </ng-container>
    <ng-container *ngIf="!icon">
      <input [hidden]="minimal" #input type="text" [disabled]="disabled" readonly
        [value]="displayText && hasSelected ? displayText : ''" (keydown)="onKeyDown($event)" (blur)="onTouched()"
        autocomplete="off" />
    </ng-container>
    <span class="placeholder" *ngIf="!minimal && !hasSelected"
      [innerText]="displayText ?? placeholder || 'INSIGHT.GLOBAL.SELECT_CHOOSE' | transloco"></span>
    <span class="dropdown-arrow">
      <uikit-icon [icon]="'chevron-down-thick'"></uikit-icon>
    </span>
  </div>

  <uikit-dropdown [reference]="dropreference" [panelClass]="dropdownPanelClass" [maxHeight]="dropdownMaxHeight"
    (visible)="isShowing($event)" #dropdownComp [ngClass]="{'compact': compact}">
    <div class="dropdown-container" [ngClass]="{'filter': filter}">
      <ng-content select="uikit-select-search"></ng-content>
      <div #dropdownOptionContainer class="dropdown-options-container">
        <ng-content></ng-content>
      </div>
    </div>
  </uikit-dropdown>
</div>
