import { ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
  selector: 'uikit-select-option-group-label',
  template: `
  <div class="wrapper">
    <ng-content />
  </div>`,
  styles: [
    `.wrapper { 
      font-size: 0.875rem;
      font-weight: bold;
      color: #828282;
      padding: 0.5rem 0.25rem;
      text-transform: uppercase;
      letter-spacing: 1.2px;
    }`
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UIKitSelectOptionGroupLabel {
}
